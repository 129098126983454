import PropTypes from 'prop-types'
import get from 'lodash/get'
import { Col, Row } from 'antd'
import Image from 'next/image'

import { Container, CTALink, Typography, GradientBlock } from 'common/widgets'
import getImgFromBynder from 'common/utils/getImgFromBynder'
import { ImageType } from '../types'
import classNames from './styles.module.scss'


HeroImageSimple.propTypes = {
  data: PropTypes.shape({
    image: ImageType,
    heading: PropTypes.string,
    text: PropTypes.string,
    cta_link_caption: PropTypes.string,
    cta_link: PropTypes.string,
    cta_link_type: PropTypes.string,
  }),
}

HeroImageSimple.defaultProps = {
  data: {},
}

export default function HeroImageSimple({ data }) {
  return (
    <div className={classNames.wrapper}>
      <Image
        src={getImgFromBynder(get(data, 'image.bynder'), ['header', 'webbig', 'webImage'])}
        alt={get(data, 'image.data.alttext')}
        title={get(data, 'image.data.name')}
        layout="fill"
        objectFit="cover"
        priority
      />
      <GradientBlock />
      <Container size="xl">
        <Row>
          <Col span={24} lg={12} xl={10}>
            <Typography variant="h2" color="white" preLine>{data.heading}</Typography>
            <Typography color="white" className={classNames.text}>{data.text}</Typography>
            <CTALink
              cta={data.cta_link_caption}
              ctaURL={data.cta_link}
              type={data.cta_link_type}
              className={classNames.cta}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
